import initialState from './state'

export function resetState(state) {
  Object.assign(state, initialState())
}

export function setBuilders(state, value) {
  state.builders = value
}

export function setSearchResults(state, value) {
  state.builderSearchResults = value
}
