import initialState from './state'
import moment from 'moment'

export function resetState(state) {
  Object.assign(state, initialState())
}

export function setInvoices(state, docs) {
  /**
     * Make a structure like
     * [{
          month: 'Month Year',
          invoices: [
            {
              ...record...
            },
            ...
          ]
        },
        {
            ...
        }
       ]
     */
  const thisMonthStr = moment(new Date()).format('MMMM YYYY')
  const thisMondayDate = moment(new Date()).startOf('isoWeek')
  let currentEndDate = thisMondayDate.clone().add(7, 'days')
  const thisMonday = moment(new Date()).startOf('isoWeek').format('DD-MM-YYYY')

  const monthMap = []
  let hasInvoiceActive = false
  state.allowNewItems = false
  state.openInvoiceCount = 0
  let startDate = null
  let endDate = null
  let foundSlot = false
  if (state.invoiceMonthly) {
    // What date in the month are we ?
    const today = new Date()
    for (const month of state.invoiceYear) {
      if (today >= month.startDate && today <= month.endDate) {
        startDate = month.startDate
        endDate = month.endDate
        currentEndDate = month.endDate
        foundSlot = true
        break
      }
    }
  }
  for (const doc of docs) {
    let headlineMonth = moment(doc.wcDate.toDate()).format('MMMM YYYY')
    const docWcDate = moment(doc.wcDate.toDate()).format('DD-MM-YYYY')
    const thisYear = moment(new Date()).format('YYYY')
    if (state.invoiceMonthly) {
      if (doc.slot) {
        headlineMonth = moment(
          moment()
            .set('month', doc.slot)
            .set('year', doc.year ? doc.year : thisYear)
        ).format('MMMM YYYY')
      }
      // What date in the month are we ?

      if (foundSlot) {
        if (doc.wcDate.toDate() >= startDate && doc.wcDate.toDate() <= endDate) {
          // Check if the active invoice is monthly or not
          if (doc.status === 'Active' && doc.monthly) {
            console.log('[!] Active monthly invoice detected')
            hasInvoiceActive = true
            state.workingInvoice = doc
          } else {
            // check if the current weekly has passed its cut off
            if (doc.status === 'Active' && doc.wcDate.toDate() <= currentEndDate) {
              console.log('[!] Active weekly invoice detected when in month mode, not yet past:', currentEndDate)
              hasInvoiceActive = true
              state.workingInvoice = doc
            } else {
              if (doc.status === 'Active') {
                console.log('[!] Active weekly invoice detected when in month mode, wcDate has past:', currentEndDate)
                hasInvoiceActive = true
                state.workingInvoice = doc
              }
            }
          }
        } else {
          if (doc.status === 'Active') {
            console.log('[!] Active invoice found outside of current slot')
            hasInvoiceActive = true
            state.workingInvoice = doc
          }
        }
      } else {
        console.log('[!] No open slot found')
      }
    } else {
      // If weekly, check if this weeks invoice exists
      if (docWcDate === thisMonday) {
        hasInvoiceActive = true
        state.workingInvoice = doc
      }
    }
    if (doc.status === 'Active') {
      // at least one invoice needs an Active status
      state.allowNewItems = true
      state.openInvoiceCount++
    }
    let ele = monthMap.find((mon) => mon.month === headlineMonth)
    if (!ele) {
      monthMap.push({ month: headlineMonth })
      ele = monthMap.find((mon) => mon.month === headlineMonth)
    }
    if (!ele.invoices) {
      ele.invoices = []
    }
    ele.invoices.push(doc)
  }

  if (!hasInvoiceActive) {
    state.workingInvoice = {}
    state.workingInvoiceItems = []
    const thisMonth = monthMap.find((mon) => mon.month === thisMonthStr)

    if (thisMonth) {
      thisMonth.invoices.unshift({
        createNew: true,
        wcDate: new Date(thisMonday)
      })
    } else {
      monthMap.unshift({
        month: thisMonth,
        invoices: [
          {
            createNew: true,
            wcDate: new Date(thisMonday)
          }
        ]
      })
    }
  }
  if (state.openInvoiceCount > 1) {
    console.log('[!] Clearing the working invoice as there are multiple open invoices. User must select one first')
    state.workingInvoice = {}
    state.workingInvoiceItems = []
  }
  state.invoices = monthMap
}

export function addToInvoice(state, value) {
  const exists = state.selectedItems.find((item) => item.id === value.id)
  if (!exists) {
    state.selectedItems.push(value)
  }
}

export function editItemOptions(state, value) {
  state.editInvoiceOptions = {}
  state.editItemOptions = value
}

export function editCustomItem(state, value) {
  state.editInvoiceOptions = {}
  state.editCustomItem = value
}

export function convertDates(state, payload) {
  // Replace any Firestore dates with string dates for datepicker
  const dateFields = payload.fields.filter((f) => f.type === 'date')
  for (const obj of payload.values) {
    const valueIds = Object.getOwnPropertyNames(obj)
    for (const id of Object.values(valueIds)) {
      if (dateFields.find((f) => f.id === id)) {
        if (obj[id] && typeof obj[id] === 'object' && typeof obj[id].seconds !== 'undefined') {
          obj[id] = moment
            .utc(new Date(obj[id].seconds * 1000))
            .local()
            .format('DD-MM-YYYY')
            .toString()
        }
      }
    }
  }
  const eventFields = payload.fields.filter((f) => f.type === 'calendarEvent')
  for (const obj of payload.values) {
    const valueIds = Object.getOwnPropertyNames(obj)
    for (const id of Object.values(valueIds)) {
      if (eventFields.find((f) => f.id === id)) {
        if (
          obj[id] &&
          typeof obj[id] === 'object' &&
          typeof obj[id].start.seconds !== 'undefined' &&
          typeof obj[id].end.seconds !== 'undefined'
        ) {
          obj[id] = {
            start: moment
              .utc(new Date(obj[id].start.seconds * 1000))
              .local()
              .format('DD-MM-YYYY')
              .toString(),
            end: moment
              .utc(new Date(obj[id].end.seconds * 1000))
              .local()
              .format('DD-MM-YYYY')
              .toString()
          }
        }
      }
    }
  }
}

export function convertNumberValues(state, payload) {
  // Replace any Number field values with the minimum amount, or zero, rather than null
  const numberFields = payload.fields.filter((f) => f.type === 'hours')
  for (const obj of payload.values) {
    const valueIds = Object.getOwnPropertyNames(obj)
    for (const id of Object.values(valueIds)) {
      const numberField = numberFields.find((f) => f.id === id)
      if (numberField) {
        if (obj[id] === null) {
          obj[id] = numberField.minimum ? numberField.minimum : 0
        }
      }
    }
  }
}

export function updateValue(state, payload) {
  state.editCustomItem.values[payload.index][payload.id] = payload.value
}

export function editInvoiceOptions(state, value) {
  state.editItemOptions = {}
  // Removed PW-167. If we delete here we lose the date for Approvals list. Moved to ManageItemOptions
  // delete value.wcDate
  // delete value.dateTime
  value.dateTime = new Date()
  state.editInvoiceOptions = value
}

export function addOptionsToEditingItem(state, obj) {
  state.editItemOptions = {
    ...state.editItemOptions,
    ...obj
  }
}

export function addOptionsToEditingInvoice(state, obj) {
  state.editInvoiceOptions = {
    ...state.editInvoiceOptions,
    ...obj
  }
}

export function removeFromInvoice(state, value) {
  state.selectedItems = state.selectedItems.filter((item) => item.id !== value.id)
}

export function clearSelectedItems(state) {
  state.selectedItems = []
}

export function clearWorkingInvoice(state, value) {
  state.workingInvoice = {}
  state.workingInvoiceItems = []
}

export function setWorkingInvoice(state, value) {
  state.workingInvoice = value
}

export function editDayworksItem(state, item) {
  state.editDayworksItem = item
}

export function setWorkingInvoiceById(state, invoiceId) {
  const invoices = state.invoices
  let invoiceDoc = null
  for (const invoiceMonth of invoices) {
    invoiceDoc = invoiceMonth.invoices.filter((doc) => doc.id === invoiceId && doc.status === 'Active')
    if (invoiceDoc.length === 1) {
      invoiceDoc = invoiceDoc[0]
      break
    }
  }
  if (Object.keys(invoiceDoc).length > 0) {
    console.log('[!] Setting working invoice to', invoiceDoc, invoiceDoc.wcDate.toDate())
    state.workingInvoice = invoiceDoc
  } else {
    console.log('[!] No working invoice found or invoice closed', invoiceDoc)
  }
}

export function setWorkingInvoiceItems(state, value) {
  state.workingInvoiceItems = value
}

export function setSelectedInvoice(state, value) {
  state.selectedInvoice = value
}

export function setSelectedInvoiceItems(state, value) {
  state.selectedInvoiceItems = value
}

export function setLastPlotsChosen(state, value) {
  state.lastPlotsChosen = value
}
export function setFireworksDisplayed(state, value) {
  state.fireworksDisplayed = true
}

export function setLastDayworksChosen(state, value) {
  state.lastDayworksChosen = value
}

export function setInvoiceSettings(state, value) {
  if (value.frequency && value.frequency === 'monthly') {
    state.invoiceMonthly = true
    const year = new Date().getYear() + 1900
    const nextYear = year + 1
    if (typeof value.yearSettings !== 'undefined' && value.yearSettings['' + year]) {
      const invoiceYear = []
      for (const month of value.yearSettings['' + year]) {
        invoiceYear.push({
          ...month,
          startDate: month.startDate.toDate(),
          endDate: month.endDate.toDate(),
          year: year
        })
      }
      // PW-403 - add Jan of next year
      if (value.yearSettings['' + nextYear]) {
        const janNextYear = value.yearSettings['' + nextYear][0]
        invoiceYear.push({
          ...janNextYear,
          startDate: janNextYear.startDate.toDate(),
          endDate: janNextYear.endDate.toDate(),
          year: nextYear
        })
      }
      state.invoiceYear = invoiceYear
    } else {
      const defaultYearArray = [
        {
          longName: 'January',
          shortName: 'Jan',
          startDate: new Date(year, 0, 1, 0, 0, 0),
          endDate: new Date(year, 1, 0, 23, 59, 59)
        },
        {
          longName: 'February',
          shortName: 'Feb',
          startDate: new Date(year, 1, 1, 0, 0, 0),
          endDate: new Date(year, 2, 0, 23, 59, 59)
        },
        {
          longName: 'March',
          shortName: 'Mar',
          startDate: new Date(year, 2, 1, 0, 0, 0),
          endDate: new Date(year, 3, 0, 23, 59, 59)
        },
        {
          longName: 'April',
          shortName: 'Apr',
          startDate: new Date(year, 3, 1, 0, 0, 0),
          endDate: new Date(year, 4, 0, 23, 59, 59)
        },
        {
          longName: 'May',
          shortName: 'May',
          startDate: new Date(year, 4, 1, 0, 0, 0),
          endDate: new Date(year, 5, 0, 23, 59, 59)
        },
        {
          longName: 'June',
          shortName: 'Jun',
          startDate: new Date(year, 5, 1, 0, 0, 0),
          endDate: new Date(year, 6, 0, 23, 59, 59)
        },
        {
          longName: 'July',
          shortName: 'Jul',
          startDate: new Date(year, 6, 1, 0, 0, 0),
          endDate: new Date(year, 7, 0, 23, 59, 59)
        },
        {
          longName: 'August',
          shortName: 'Aug',
          startDate: new Date(year, 7, 1, 0, 0, 0),
          endDate: new Date(year, 8, 0, 23, 59, 59)
        },
        {
          longName: 'September',
          shortName: 'Sep',
          startDate: new Date(year, 8, 1, 0, 0, 0),
          endDate: new Date(year, 9, 0, 23, 59, 59)
        },
        {
          longName: 'October',
          shortName: 'Oct',
          startDate: new Date(year, 9, 1, 0, 0, 0),
          endDate: new Date(year, 10, 0, 23, 59, 59)
        },
        {
          longName: 'November',
          shortName: 'Nov',
          startDate: new Date(year, 10, 1, 0, 0, 0),
          endDate: new Date(year, 11, 0, 23, 59, 59)
        },
        {
          longName: 'December',
          shortName: 'Dec',
          startDate: new Date(year, 11, 1, 0, 0, 0),
          endDate: new Date(year, 12, 0, 23, 59, 59)
        }
      ]
      state.invoiceYear = defaultYearArray
    }
  }
  state.invoiceSettings = value
}

export function setEngineerPendingItems(state, value) {
  state.pendingItems = value
}

export function setEngineerRecentlyApproved(state, value) {
  state.recentlyApproved = value.slice(0, 5)
}

export function setCustomApprovalItems(state, value) {
  state.approvalItems = value
}
