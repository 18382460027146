import {
  firebaseAuth,
  createTenantFirestore,
  readOnlyGlobalCollection,
  firebaseRemoteConfig,
  firebaseFunctions
} from 'boot/firebase'
import rootStore from '../index'
import { i18n } from 'src/boot/i18n'
import { Loading, Dialog, LocalStorage, Platform } from 'quasar'
import { orderBy } from 'natural-orderby'
// import LogRocket from 'logrocket'
import { H } from 'highlight.run'

import helpers from 'src/plugins/helpers'

export async function activateUserAccount({ state }, payload) {
  const url = `https://europe-west2-${firebaseFunctions.app.options.projectId}.cloudfunctions.net/users-activateUser`
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
  if (!response.ok) {
    const data = await response.json()
    throw new Error(data.error)
  }
}

export async function resetPassword({ state, commit }, payload) {
  var emailLookupFn = firebaseFunctions.httpsCallable('auth-lookupUserByEmail')
  const tenantLookupResponse = await emailLookupFn({ email: payload.email })
  var tenants = []
  if (!payload.tenantId) {
    if (tenantLookupResponse.data.length > 0) {
      tenants = tenantLookupResponse.data
      if (tenants.length === 0) {
        commit('setTenantSelectRequired', false)
        commit('setAvailableTenants', [])
        payload.tenantId = null
        throw new Error(i18n.t('login.messages.noTenantsAvailable'))
      } else if (tenants.length === 1) {
        commit('setTenantSelectRequired', false)
        commit('setAvailableTenants', [])
        payload.tenantId = tenants[0].tenantId
      } else {
        commit('setAvailableTenants', orderBy(tenants, [(v) => v.tenantName], ['asc']))
        commit('setTenantSelectRequired', true)
        Loading.hide()
        return false // exit quietly
      }
    } else {
      commit('setTenantSelectRequired', false)
      commit('setAvailableTenants', [])
      throw new Error(i18n.t('login.messages.invalidCredentials'))
    }
  }
  commit('setTenantSelectRequired', false)
  firebaseAuth.tenantId = payload.tenantId
  await firebaseAuth.sendPasswordResetEmail(payload.email, {
    url: process.env.PWA_URL
  })
}

export async function getEulaStatus({ state, commit }) {
  const user = firebaseAuth.currentUser
  if (!user) {
    throw new Error('User not signed in [EULA]')
  }
  const tokenResult = await user.getIdTokenResult()
  const tenantId = tokenResult.claims.tenantId
  const eulaState = LocalStorage.getItem(`pw_app_eula_${tenantId}_acpt`)
  var ttl = LocalStorage.getItem(`pw_app_eula_${tenantId}_t`)
  if (!ttl) {
    ttl = 0
  }
  const elapsed = 1000 * 60 * 60 * 24 // one day
  const ttlActive = Date.now() - ttl < elapsed
  if (!ttlActive) {
    console.log('%c[!] EULA TTL has expired, force recheck', 'background-color: orange; color: black;')
  }
  if (eulaState && ttlActive) {
    console.log(`%c[!] EULA status is ${eulaState} from local`, 'background-color: orange; color: black;')
    return true
  } else {
    const firebaseStore = await createTenantFirestore()

    const profile = await firebaseStore.collection('users').doc(firebaseAuth.currentUser.uid).get()
    if (profile.exists && profile.data().eulaAccepted) {
      console.log(
        `%c[!] EULA status is ${profile.data().eulaAccepted} from remote`,
        'background-color: orange; color: black;'
      )
      LocalStorage.set(`pw_app_eula_${tenantId}_acpt`, true)
      LocalStorage.set(`pw_app_eula_${tenantId}_t`, Date.now())
      return true
    } else {
      console.log('%c[!] EULA status is required', 'background-color: red; color: white;')
      return false
    }
  }
}
export async function updateEula({ state, commit, dispatch }, value) {
  const user = firebaseAuth.currentUser
  if (!user) {
    throw new Error('User not signed in [EULA]')
  }
  const tokenResult = await user.getIdTokenResult()
  const tenantId = tokenResult.claims.tenantId
  const firebaseStore = await createTenantFirestore()
  await firebaseStore
    .collection('users')
    .doc(firebaseAuth.currentUser.uid)
    .update({
      eulaAccepted: value,
      eulaAcceptedDate: value ? new Date() : null
    })
  LocalStorage.set(`pw_app_eula_${tenantId}_acpt`, value)
  LocalStorage.set(`pw_app_eula_${tenantId}_t`, Date.now())
  commit('setEulaAccepted', value)
}

export async function getEulaContent() {
  const readOnlyFirestore = await readOnlyGlobalCollection()
  const doc = await readOnlyFirestore.collection('eula').doc('app').get()
  if (doc.exists) {
    return doc.data().html
  } else {
    return i18n.t('errors.missingEula')
  }
}

async function loadDependentStates(commit, dispatch) {
  // TO reset uncomment this and run once:
  // LocalStorage.remove(`pw_app_gps_${firebaseAuth.currentUser.uid}_acpt`)

  const firebaseStore = await createTenantFirestore()
  const userProfile = await firebaseStore.collection('users').doc(firebaseAuth.currentUser.uid).get()

  if (userProfile.exists) {
    const tokenResult = await firebaseAuth.currentUser.getIdTokenResult(true)
    let userObj = {
      ...JSON.parse(JSON.stringify(userProfile.data()))
    }
    if (userProfile.data().profile) {
      const profileData = await firebaseStore.collection('profiles').doc(userProfile.data().profile).get()

      if (profileData.exists) {
        userObj = {
          ...userObj,
          ...JSON.parse(JSON.stringify(profileData.data()))
        }
      }
    }
    commit('setUserProfile', userObj)
    let engineerName = ''

    if (userProfile.data().sharedUser) {
      for (const name of userProfile.data().names) {
        engineerName += `${name.firstName} ${name.lastName}, `
      }
      engineerName = engineerName.replace(/,\s*$/, '')
    } else {
      engineerName = `${userProfile.data().firstName} ${userProfile.data().lastName}`
    }

    // LogRocket.identify(firebaseAuth.currentUser.uid, {
    //   name: `${engineerName} (${tokenResult.claims.tenantId ? tokenResult.claims.tenantId : 'unknown'})`,
    //   email: userProfile.data().email,
    //   roleName: userProfile.data().roleName,
    //   tenantId: tokenResult.claims.tenantId ? tokenResult.claims.tenantId : 'unknown',
    //   sharedUser: userProfile.data().sharedUser,
    //   engineerSession: true
    // })
    H.identify(userProfile.data().email, {
      id: firebaseAuth.currentUser.uid,
      name: `${engineerName} (${tokenResult.claims.tenantId ? tokenResult.claims.tenantId : 'unknown'})`,
      roleName: userProfile.data().roleName,
      tenantId: tokenResult.claims.tenantId ? tokenResult.claims.tenantId : 'unknown',
      sharedUser: userProfile.data().sharedUser,
      engineerSession: true
    })
  } else {
    throw new Error(i18n.t('errors.noProfile'))
  }

  const generalSettings = await firebaseStore.collection('settings').doc('general').get()
  if (generalSettings.data()) {
    commit('settings/setGeneralSettings', generalSettings.data(), {
      root: true
    })
  }
  // PW-311 admin can be an engineer too!
  if (!generalSettings.data().adminLoginAsEngineer) {
    const tokenResult = await firebaseAuth.currentUser.getIdTokenResult(true)
    if (tokenResult.claims.admin) {
      throw new Error(i18n.t('errors.noAdminAccess', { adminUrl: process.env.ADMIN_URL }))
    }
  }

  // Push Init
  if (Platform.is.android || Platform.is.ios) {
    await dispatch('messages/initPush', null, { root: true })
  }

  if (generalSettings.data().enableGeofencingCapability && generalSettings.data().geofencingEnabled) {
    if (Platform.is.capacitor && Platform.is.ios) {
      /* Initialise GPS module */
      await dispatch('signin/initTracking', null, { root: true })
      await dispatch('signin/getGpsPermissionStatus', null, { root: true })
      await dispatch('signin/startTrackingOnStartup', 3, { root: true })
    } else if (Platform.is.capacitor && Platform.is.android) {
      const gpsPermissionGranted = LocalStorage.getItem(`pw_app_gps_${firebaseAuth.currentUser.uid}_acpt`)
      console.log(
        '[BackgroundGeoLocation] Android Prompt permission state at startup',
        gpsPermissionGranted,
        typeof gpsPermissionGranted
      )
      if (gpsPermissionGranted === true) {
        /* Initialise GPS module */
        await dispatch('signin/initTracking', null, { root: true })
        await dispatch('signin/getGpsPermissionStatus', null, { root: true })
        await dispatch('signin/startTrackingOnStartup', 3, { root: true })
      } else {
        // default to denied
        commit('signin/setPermissionState', 'denied', { root: true })
        commit('signin/setGpsDenied', true, { root: true })
        commit('signin/setGpsAlwaysOn', false, { root: true })
        commit('signin/setPluginReady', true, { root: true })
      }
    } else {
      console.log('[!] Cannot start GPS on unsupported device')
    }
  }
  const invoiceSettings = await firebaseStore.collection('settings').doc('invoices').get()
  if (invoiceSettings.data()) {
    commit('invoices/setInvoiceSettings', invoiceSettings.data(), {
      root: true
    })
  } else {
    throw new Error(i18n.t('errors.noInvoiceSettings'))
  }

  /* Load Categories as they're needed for sorting */
  dispatch('categories/getCategories', null, { root: true })
}

export async function loginChange({ state, commit, dispatch, rootState }, user) {
  commit('setLoggedIn', user !== null)
  if (!state.authInitialised) {
    commit('setAuthInitialised', true)
    console.log('[*] Auth handler initialised')
  }
  if (user) {
    try {
      if (!state.stateInitialised) {
        await loadDependentStates(commit, dispatch)
      }

      await firebaseRemoteConfig.fetchAndActivate()

      /* Get the number of approvals requiring attention */
      dispatch('invoices/getEngineerDraftFeed', null, { root: true }) // we don't need to await for this
      dispatch('invoices/getEngineerApprovalFeed', null, { root: true }) // we don't need to await for this. badly named, should say DeclinedFeed
      dispatch('invoices/getEngineerPendingFeed', null, { root: true }) // we don't need to await for this
      dispatch('invoices/getEngineerApprovedFeed', null, { root: true }) // we don't need to await for this

      commit('setLoggedIn', true)
      await helpers.checkLocationNotificationPermissions()

      this.$router.replace('/').catch(() => {})
    } catch (error) {
      console.log('[!] Error', error)
      Loading.hide()
      Dialog.create({
        title: i18n.t('errors.error'),
        html: true,
        message: error.message
      }).onOk(() => {
        dispatch('signOut')
        this.$router.replace('/login?e=1').catch(() => {})
      })
    }
  } else {
    if (window.location && window.location.href && window.location.href.includes('login/register')) {
      const fullUrl = window.location.href
      const domain = window.location.origin
      const path = fullUrl.substring(domain.length + 2) // strip the /#
      this.$router.replace(path).catch(() => {})
    } else {
      this.$router.replace('/login').catch(() => {})
    }
  }
}

// export function signIn(context, payload) {
//   Loading.show()
//   firebaseAuth
//     .signInWithEmailAndPassword(payload.user, payload.password)
//     .then(result => {
//       //      context.commit('setLoggedIn', true)
//       Loading.hide()
//       this.$router.replace('/').catch(() => {})
//     })
//     .catch(error => {
//       Loading.hide()
//       console.log('[!] Sign in error', error)
//       Dialog.create({
//         title: i18n.t('errors.error'),
//         message: error.message
//       })
//     })
// }

export async function signIn({ commit, dispatch, state }, payload) {
  Loading.show()
  var emailLookupFn = firebaseFunctions.httpsCallable('auth-lookupUserByEmail')
  const tenantLookupResponse = await emailLookupFn({ email: payload.user })
  var tenants = []
  if (!payload.tenantId) {
    if (tenantLookupResponse.data.length > 0) {
      tenants = tenantLookupResponse.data
      if (tenants.length === 0) {
        commit('setTenantSelectRequired', false)
        commit('setAvailableTenants', [])
        payload.tenantId = null
        throw new Error(i18n.t('login.messages.noTenantsAvailable'))
      } else if (tenants.length === 1) {
        commit('setTenantSelectRequired', false)
        commit('setAvailableTenants', [])
        payload.tenantId = tenants[0].tenantId
      } else {
        commit('setAvailableTenants', orderBy(tenants, [(v) => v.tenantName], ['asc']))
        commit('setTenantSelectRequired', true)
        Loading.hide()
        return false // exit quietly
      }
    } else {
      commit('setTenantSelectRequired', false)
      commit('setAvailableTenants', [])
      payload.tenantId = null
      throw new Error(i18n.t('login.messages.invalidCredentials'))
    }
  }

  if (!payload.tenantId) {
    throw new Error(i18n.t('login.messages.userNotFound'))
  }
  console.log('[!] Signing in with Tenant ID', payload.tenantId)
  firebaseAuth.tenantId = payload.tenantId

  try {
    await firebaseAuth.signInWithEmailAndPassword(payload.user, payload.password)
    if (!state.stateInitialised) {
      await loadDependentStates(commit, dispatch)
    }
    LocalStorage.set('pwei_last_user', payload.user)
    Loading.hide()
    commit('setLoggedIn', true)
    this.$router.replace('/').catch(() => {})
  } catch (error) {
    Loading.hide()
    throw new Error(error)
  }
}

export function signOut({ state, commit, dispatch, rootState }) {
  Loading.show()
  firebaseAuth
    .signOut()
    .then(() => {
      clearStoreStates(commit)
      Loading.hide()
      console.log('[!] Signed out of Priceworx')
    })
    .catch((error) => {
      Loading.hide()
      console.log('[!] Sign-out error', error)
    })
}

function clearStoreStates(commit, rootState) {
  console.log('[!] Clearing session cache')
  const rootModule = rootStore()._modules.root // Get the root module
  // Loop through the registered module names
  Object.keys(rootModule._children).forEach((moduleName) => {
    // Construct the action name based on the module name

    const resetAction = `${moduleName}/resetState`
    // Dispatch the reset action for each module
    commit(resetAction, {}, { root: true })
  })
}
