import { Device } from '@capacitor/device'
import { version } from '../../package.json'
export default async ({ router, store, Vue }) => {
  store.commit('signin/setVersion', version)
  const deviceId = await Device.getId()
  const deviceInfo = await Device.getInfo()
  if (deviceId && deviceId.identifier) {
    store.commit('signin/setDeviceId', deviceId.identifier, { root: true })
  }
  if (deviceInfo) {
    store.commit('signin/setDeviceInfo', deviceInfo, { root: true })
    console.log('[!] DEVICE INFO: ', JSON.stringify(deviceInfo))
  }
}
