import Vue from 'vue'
import VueRouter from 'vue-router'
import { firebaseAuth } from 'boot/firebase'
import { Loading } from 'quasar'
import routes from './routes'

Vue.use(VueRouter)

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

export default function ({ store }) {
  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as they are and change in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE
  })

  firebaseAuth.onAuthStateChanged(user => {
    store.dispatch('auth/loginChange', user)
  })

  Router.beforeEach((to, from, next) => {
    if (store.state.auth.authInitialised) { // Don't flicker the login page if onAuthStateChanged isn't ready
      Loading.hide()
      const loggedIn = store.state.auth.isLoggedIn
      const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
      console.log(`[!] Logged in status: ${loggedIn}`)
      if (requiresAuth && !loggedIn) {
        Router.replace('/login').catch(() => { })
      } else {
        next()
      }
    } else {
      Loading.show({
        message: 'Loading...'
      })
    }
  })

  return Router
}
