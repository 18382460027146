export default {
  isoName: 'en-us',
  nativeName: 'English (US)',
  priceworx: 'Priceworx',
  copyright: `&copy; ${new Date().getFullYear()} Priceworx Ltd`,
  version: '2.5.0',
  navigation: {
    invoices: 'Invoices',
    inventory: 'Inventory',
    builders: 'Builders',
    installations: 'Installations',
    approvals: 'Approvals',
    developments: 'Developments',
    plots: 'Plots',
    installationItems: 'Installation Items',
    selectOptions: 'Options',
    help: 'Help & Support',
    signOut: 'Sign Out',
    searchResults: '{count} Search Result{plural}',
    eula: 'End User License Agreement',
    eulaShort: 'EULA',
    privacy: 'Privacy Policy',
    qualityControl: 'Quality Control',
    sitePlans: 'Site Plans',
    technicalDrawings: 'Technical Drawings',
    takePhoto: 'Take Photo',
    openGallery: 'Open Gallery',
    siteSignIn: 'Site Sign-In',
    documentScanner: 'Document Scanner',
    locationPromptTitle: 'Use your location',
    locationPrompt:
      'This app collects location geofence entry and exit data to enable the Site Sign-In/Out feature, even when the app is closed or not in use.',
    noThanks: 'No thanks',
    turnOn: 'Turn On'
  },
  update: {
    updateApplied: 'Update Applied',
    updateMessage: 'An updated version of the Priceworx app has been installed. The app will now refresh.',
    updateButton: 'Refresh'
  },
  login: {
    dashboard: 'Operative App',
    login: 'Login',
    emailOrUser: 'Email address or username',
    username: 'Email address',
    password: 'Password',
    signUp: 'Sign Up',
    selectTenant: 'Select Tenant',
    forgottenPassword: 'Forgotten your password?',
    reset: 'Reset Password',
    terms: 'Terms of Use',
    privacy: 'Privacy Policy',
    createAccount: 'Create Account',
    confirmPassword: 'Confirm Password',
    messages: {
      passwordResetSent: 'Instructions have been sent to <b>{email}</b> detailing how to reset your password.',
      sharedMailboxNoReset: 'To reset a shared user account please contact your administrator',
      proudlyWorkingWith: 'Proudly working with',
      userNotFound: 'The username does not exist or has been deactivated',
      noTenantsAvailable: 'Unexpected Login Failure',
      invalidCredentials: 'Invalid Credentials',
      passwordMismatch: 'The passwords do not match. Please check and try again.',
      createAccountSuccess: 'Your account has been successfully activated!'
    }
  },
  eulaPrompt: {
    messages: {
      declineConfirm: 'Declining the End User License Agreement will log you out of the application. Are you sure?'
    }
  },
  approvals: {
    draft: 'Draft',
    actionNeeded: 'Action Needed',
    pending: 'Pending',
    recent: 'Recently Approved',
    newApproval: 'New Approval',
    selectApprovalCategory: 'Select Approval Category',
    exportPdf: 'Export to PDF',
    viewPdf: 'View PDF',
    requestPdf: 'Request PDF',
    messages: {
      removeDocument: 'Are you sure you want to remove page {page} from the document {label}?',
      notSupported:
        'You are using a device that does not support Document Scanning. Please swap to the iOS or Android app instead.',
      noActionNeeded: 'You have no approval items that currently require your attention.',
      noPending: 'You have no pending approval items.',
      noRecent: 'You have no recently approved items.',
      invalidApprovalItem: 'Invalid Approval Item. This error is unexpected.',
      noFields: 'This Approval Item is not yet ready for use. Please check back again soon.',
      formInvalid: 'Please correct the form errors displayed in red',
      updateCustomItem: 'Successfully updated {name}',
      addCustomItem: 'Successfully added {name}',
      signatureRequiredMessage: 'You must sign the Dayworks request',
      signatureNameRequired: 'You must provide the signatories name',
      signatureRequiredFor: 'Signature missing for {label}',
      printedNameRequiredFor: 'Printed name missing for {label}',
      toggleRequired: 'The toggle {label} must be selected',
      radioRequired: '{label} must be answered',
      invalidImageFormat:
        'The HEIC image format is not supported. Please turn it off within your phones settings and re-take the image',
      missingImage: 'The photo {label} is missing',
      missingDocument: 'The document {label} is missing',
      missingImageGroup: 'At least one photo is required for {label}',
      devPickerInvalid: 'You must select a builder and a development',
      plotPickerInvalid: 'You must select a builder, development and plot',
      missingForm: 'This Custom Category does not have a form assigned'
    }
  },
  plotPicker: {
    builder: 'Builder',
    development: 'Development',
    plot: 'Plot',
    plotOptional: 'Plot (optional)'
  },
  invoices: {
    year: 'Year',
    month: 'Month',
    messages: {
      noInvoices: 'There are currently no invoices',
      invoiceExists:
        'This invoice has already been created. Please refresh the Invoices section to view it, or if visible, request the administrator to unlock it for editing.',
      itemsUnavailable: 'Some items could not be added as they have been assigned by other engineers.',
      noInvoiceSlotAvailable:
        'Invoicing is currently closed. Please wait for the invoicing period to start and try again.',
      multipleOpenInvoices: 'When multiple invoices are active you must select the invoice first to add items.',
      noOpenInvoices:
        'There are currently no open invoices. Please re-open an invoice or wait until the next period to create a new invoice.',
      unexpectedInvoiceState: 'Unexpected invoice state. Please contact the administrator'
    }
  },
  invoicePicker: {
    period: 'Period',
    month: 'month',
    months: 'months',
    year: 'year',
    years: 'Years',
    forever: 'Forever'
  },
  invoiceDetail: {
    plot: 'Plot',
    total: 'Total',
    invoiceTarget: 'of {target}',
    otherApprovals: 'Other Approvals',
    adjustments: 'Adjustments',
    invoiceAdjustments: 'Invoice Adjustments',
    messages: {
      invalidInvoice: 'Missing or invalid invoice. Please contact the helpdesk.',
      invoiceEmpty: 'The invoice is empty. Tap + to add your first item',
      invoiceEmptyDayworx: 'The invoice is empty. Go to Approvals to add your first item',
      deleteItemConfirm: 'Are you sure you want to delete the invoice item: <b>{name}</b>?',
      deleteMultipleItemConfirm: 'Are you sure you want to delete <b>{count}x {name}</b> invoice items?',
      itemsRemoved: '{count} line item{plural} removed from invoice',
      draftRemoved: '{itemName} has been removed',
      itemNoLongerAvailable:
        'The form could not be submitted as this item has already been booked. Your draft has been removed',
      draftSignatureProvided:
        'You cannot save a draft with signature information. Saving this draft will remove the signature information. Are you sure ? If you are ready to submit the draft, use the <b>Submit</b> button instead.'
    }
  },
  installationItems: {
    addToInvoice: 'ADD {total} TO INVOICE',
    qualityControl: 'Quality Control',
    customForm: 'Form',
    hasDraft: 'Draft',
    options: {
      title: '{OptionType} Quality Control Item',
      addPhotos: 'Add Photos',
      updatePhotos: 'Update Photos',
      addDescription: 'Add Description',
      updateDescription: 'Update Description',
      variationOrder: 'Variation Order',
      variationOrderRequired: 'Is a Variation Order required?',
      description: 'Description',
      descriptionPlaceholder: 'e.g. describe the work carried out or time it took',
      voPlaceholder: 'e.g. describe the extra works required',
      requestedAmount: 'Requested Hours',
      signatureText: 'Must be signed by authorised site management',
      signature: 'Signature',
      printName: 'Print Name',
      voApproved: 'This Variation Order has been approved at {variation}',
      voDeclined: 'This Variation Order has been declined',
      voPending: 'This Variation Order is pending review'
    },
    messages: {
      noItems: 'All job items have been assigned or are complete.',
      signatureRequired: 'A signature is required for the variation order.',
      photoRequired: 'At least one photo of the work is required.',
      descriptionRequired: 'A description of the work is required. Please enter at least 10 characters.',
      voDescriptionRequired:
        'A description of the variation to the work is required. Please enter at least 10 characters.',
      requestedAmountRequired: 'A variation on the requested amount is required.',
      printNameRequired: 'The name of the signatory is required',
      unauthorized: 'File upload was unauthorised',
      uploadCancelled: 'The upload has been cancelled',
      addedItemWithOptions: 'Item has been added to your invoice',
      removeImage: 'Are you sure you want to remove the {num}{suffix} image?',
      imageLoading: 'One or more photographs are still being processed. Please try again shortly.',
      duplicatePhoto: 'Quality Control photographs must be unique and cannot already have been submitted.',
      invalidImageFormat:
        'The HEIC image format is not supported for image {number}. Please turn it off within your phones settings and re-take the image',
      unableToLoad: 'Unable to load installations. Please try again'
    }
  },
  builders: {
    messages: {
      noBuilders: 'No builders were found'
    }
  },
  developments: {
    dayWorks: {
      builder: 'Builder',
      development: 'Development',
      plot: 'Plot',
      developmentsTitle: 'Dayworks / Travel',
      plotsTitle: 'Dayworks',
      dayWorks: 'Dayworks',
      dayWorksInfo: 'Add Dayworks items to {development}',
      updateDayWorksInfo: 'Update Dayworks item for {development}',
      dayWorksInfoReadOnly: '{item} for {development}',
      customItemPath: '{builder} - {development}',
      dayWorksDescr: 'e.g. describe the extra works',
      travelDescr: 'e.g. add any travel notes or fuel receipt',
      travel: 'Travel',
      addTravelTo: 'Add travel to {development}',
      addTravelToReadOnly: '{item} for {development}',
      travelDescription: 'Here you can request Hours or Mileage for travel. You can also enter both if required',
      requestedHours: 'Requested Hours',
      requestedMileage: 'Requested Mileage',
      addAnother: 'Add Another',
      removeLastPanel: 'Are you sure you want to remove the last DayWorks entry?',
      removeImage: 'Are you sure you want to remove this image?',
      removeImageTravel: 'Are you sure you want to remove the travel related image?',
      hours: 'hour{plural}',
      miles: 'mile{plural}',
      missingRequestedHours: 'Dayworks entry {panel} is missing requested hours',
      missingDescription: 'Dayworks entry {panel} is mssing a description',
      missingDescriptionTravel: 'Travel entry is mssing a description',
      oneTravelUnitOnly: 'Please use either hours or mileage to report travel',
      panelIncomplete: 'Dayworks entry {panel} must be completed or removed',
      noDayworks: 'You must enter at least one Dayworks to add this item to your invoice',
      noTravel: 'You must enter in Travel details to add this item to your invoice',
      addedDayworksItems: 'Successfully added {count} dayworks items to your invoice',
      updatedDayworksItems: 'Successfully updated dayworks item',
      approved: 'This {itemType} item has been approved at {variation} by {by} on {on}',
      approvedHours: 'This {itemType} item has been approved at {variation}hrs by {by} on {on}',
      declined: 'This {itemType} item has been declined by {by} on {on}',
      pending: 'This {itemType} item is pending review',
      notesFromAdmin: 'Message from {by}:',
      backToInvoice: 'Back to {type}',
      signatureRequired: 'Is site management approval required?',
      signatureRequiredMessage: 'You must sign this form',
      signature: 'Signature',
      signatureText: 'Must be signed by authorised site management',
      signatureName: 'Print Name',
      signatureNameRequired: 'You must provide the signatories name',
      invalidImageFormat:
        'The HEIC image format is not supported. Please turn it off within your phones settings and re-take the image'
    },
    messages: {
      noDevelopments: 'No developments were found for the selected builder',
      noPlans: 'No Site Plans were found for the selected development',
      noPlanCategories: 'No Drawing Categories were found for the selected development'
    }
  },
  plots: {
    sitePlans: 'Development Information',
    messages: {
      noPlots: 'No plots were found for the selected development',
      noDrawings: 'No Technical Drawings were found for the selected development'
    }
  },
  sitePlans: {
    messages: {
      noPlans: 'No Site Plans were found for the selected development',
      noPlanCategories: 'No Site Plan Categories were found for the selected development'
    }
  },
  selectOption: {
    installationItems: 'Installation Items',
    installations: 'Installations',
    qualityControl: 'Quality Control',
    technicalDrawings: 'Technical Drawings'
  },
  qualityControl: {
    messages: {
      noImages: 'There are currently no Quality Control images available. Please check back again later.'
    }
  },
  siteSignIn: {
    signIn: 'Sign In',
    signOut: 'Sign Out',
    visualiser: 'Visualiser',
    in: 'In',
    out: 'Out',
    duration: 'Duration',
    invalid: 'Invalid',
    autoToggle: 'Auto Sign-In',
    totalDuration: 'Total Duration: {hours}hrs',
    recentActivity: 'Recent Activity',
    openSettings: 'Open Settings',
    messages: {
      noPermissionShort:
        'Please allow Priceworx to "Always" access your location within your device settings to use this feature.',
      noPermissions:
        'Please allow Priceworx to "Always" access your location within your device settings to use this feature. Priceworx only stores location data once you\'re within the site radius, we will never store or have access to data outside of this radius.',
      noSiteDetected:
        "We can't detect the site you're currently on. This is either because you are outside of the site radius or your administrator hasn't configured the site for sign-in.",
      siteDetectedWhileUsing: "We've detected that you're on site at {builderName} - {developmentName}",
      signIn: 'You signed in to {builderName} - {developmentName} at {timestamp}',
      autoSignOut: 'You will automatically be signed out when you leave site',
      signOutNote: "NOTE: You must be on site and about to leave to sign out. You won't be able to sign out off-site.",
      notSupported:
        'You are using a device that does not support Geo-Fence capabilities. Please swap to the iOS or Android app instead.',
      errorGpsModule: 'There was an error initialising the GPS module. Please contact the helpdesk.',
      tryAgainStartGps:
        'Priceworx was unable to start monitoring for Site Sign-In functionality. Try again? Cancelling will disable this feature until next time you run the app',
      noRecentActivity: 'You have no recent Sign-In activity',
      signInNotification: 'You signed in to {builderName} - {developmentName}',
      signOutNotification: 'You signed out of {builderName} - {developmentName}'
    }
  },
  errors: {
    invoicingDisabled:
      'Invoicing has been closed by your administrator. Please contact them to reopen if you believe it has been incorrectly closed.',
    noInvoiceSettings: 'Invoice Settings have not been configured',
    invalidImage: 'The image could not be loaded. Please try again',
    networkItemsError:
      'Network lost - The app will refresh. Please check the items were added correctly to your invoice.',
    noProfile: 'Unable to load user profile',
    noWorkingInvoice: 'Please open an invoice for the current work period',
    noActiveInvoice: 'There are no active invoices to add this item to.',
    noSearchResults: 'No matches were found. Please try refining your search.',
    noSearchResultTip: 'Enter the first few letters of a builder, development or plot.',
    invoiceIsCompleted: 'Please contact your office admin to reopen your invoice to make changes to this {type} item',
    minimumSearchTerm: 'Enter at least 3 letters to search',
    noAdminAccess:
      'Logging in with an admin user is not permitted. Please log in to the <a href="{adminUrl}">admin dashboard</a> instead.',
    warning: 'Warning',
    problem: 'Problem',
    invalidEmail: 'Invalid email address',
    invalidDate: 'The date is invalid',
    error: 'Error',
    fieldRequired: '{label} is required',
    missingEula: 'Missing End User License Agreement. Please contact the helpdesk.'
  },
  label: {
    submit: 'Submit',
    saveDraft: 'Save Draft',
    updateDraft: 'Update Draft',
    convertingPhoto: 'Converting Photo...',
    processingPhoto: 'Processing Photo...',
    confirm: 'Action Required',
    start: 'Start',
    end: 'End',
    field: 'Field',
    invoice: 'Invoice',
    approvals: 'Approvals',
    on: 'on',
    unknown: '(Unknown)',
    hours: 'hours',
    hoursShort: 'hrs',
    suffixSt: 'st',
    suffixNd: 'nd',
    suffixTh: 'th',
    suffixRd: 'rd',
    required: 'Required',
    optional: 'Optional',
    uploading: 'Uploading',
    itemXofY: 'Item {x} of {y}',
    success: 'Success',
    accept: 'Accept',
    decline: 'Decline',
    back: 'Back',
    save: 'Save',
    all: 'All',
    emailAddress: 'Email address',
    password: 'Password',
    add: 'Add',
    addAnother: 'Add Another',
    delete: 'Delete',
    clear: 'Clear',
    ok: 'OK',
    cancel: 'Cancel',
    close: 'Close',
    set: 'Set',
    select: 'Select',
    reset: 'Reset',
    remove: 'Remove',
    update: 'Update',
    create: 'Create',
    search: 'Search',
    filter: 'Filter',
    refresh: 'Refresh'
  },
  date: {
    days: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
    daysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
    months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
    monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
    firstDayOfWeek: 1, // 0-6, 0 - Sunday, 1 Monday, ...
    format24h: true,
    pluralDay: 'days'
  },
  table: {
    noData: 'No data available',
    noResults: 'No matching records found',
    loading: 'Loading...',
    selectedRecords: function (rows) {
      return rows === 1 ? '1 record selected.' : (rows === 0 ? 'No' : rows) + ' records selected.'
    },
    recordsPerPage: 'Records per page:',
    allRows: 'All',
    pagination: function (start, end, total) {
      return start + '-' + end + ' of ' + total
    },
    columns: 'Columns'
  },
  editor: {
    url: 'URL',
    bold: 'Bold',
    italic: 'Italic',
    strikethrough: 'Strikethrough',
    underline: 'Underline',
    unorderedList: 'Unordered List',
    orderedList: 'Ordered List',
    subscript: 'Subscript',
    superscript: 'Superscript',
    hyperlink: 'Hyperlink',
    toggleFullscreen: 'Toggle Fullscreen',
    quote: 'Quote',
    left: 'Left align',
    center: 'Center align',
    right: 'Right align',
    justify: 'Justify align',
    print: 'Print',
    outdent: 'Decrease indentation',
    indent: 'Increase indentation',
    removeFormat: 'Remove formatting',
    formatting: 'Formatting',
    fontSize: 'Font Size',
    align: 'Align',
    hr: 'Insert Horizontal Rule',
    undo: 'Undo',
    redo: 'Redo',
    heading1: 'Heading 1',
    heading2: 'Heading 2',
    heading3: 'Heading 3',
    heading4: 'Heading 4',
    heading5: 'Heading 5',
    heading6: 'Heading 6',
    paragraph: 'Paragraph',
    code: 'Code',
    size1: 'Very small',
    size2: 'A bit small',
    size3: 'Normal',
    size4: 'Medium-large',
    size5: 'Big',
    size6: 'Very big',
    size7: 'Maximum',
    defaultFont: 'Default Font',
    viewSource: 'View Source'
  },
  tree: {
    noNodes: 'No nodes available',
    noResults: 'No matching nodes found'
  }
}
