import firebase from 'firebase/app' // firebase 8.0+
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/remote-config'

let firebaseConfig = {}

// if (process.env.DEPLOY_MODE === 'STAGING') {
//   // STAGING will share the same .env.prod
//   firebaseConfig = {
//     apiKey: process.env.FIREBASE_STAGING_API_KEY,
//     authDomain: process.env.FIREBASE_STAGING_AUTH_DOMAIN,
//     databaseURL: process.env.FIREBASE_STAGING_DATABASE_URL,
//     projectId: process.env.FIREBASE_STAGING_PROJECT_ID,
//     storageBucket: process.env.FIREBASE_STAGING_STORAGE_BUCKET,
//     messagingSenderId: process.env.FIREBASE_STAGING_MESSAGING_SENDER_ID,
//     appId: process.env.FIREBASE_STAGING_APP_ID,
//     measurementId: process.env.FIREBASE_STAGING_MEASUREMENT_ID
//   }
// } else {
// DEV or PRODUCTION
firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
}
// }

const firebaseApp = firebase.initializeApp(firebaseConfig)
const firebaseAuth = firebaseApp.auth()
const db = firebaseApp.firestore()
const firebaseFunctions = firebaseApp.functions('europe-west2')
const firebaseStorage = firebaseApp.storage()
const firebaseRemoteConfig = firebase.remoteConfig()
firebaseRemoteConfig.defaultConfig = {
  plan: 'ultimate'
}
if (process.env.DEPLOY_MODE === 'DEV') {
  firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 60000 // 1 minute
  // firebaseFunctions.useEmulator('127.0.0.1', 5001)
} else {
  firebaseRemoteConfig.settings.minimumFetchIntervalMillis = 900000 // 15 minutes
}

async function createTenantFirestore() {
  const user = firebaseAuth.currentUser
  if (!user) {
    throw new Error('User not signed in')
  }

  const tokenResult = await user.getIdTokenResult()
  const tenantId = tokenResult.claims.tenantId
  // Firestore structure is /{tenantId}/account/{subCollection} where 'account' is a document
  // Essentially db.collection(tenantId).doc('account').collection(...)
  // This sets the base for all queries to the specific tenant for safety
  const accountDoc = db.collection(tenantId).doc('account')

  function invoiceItemsCollection() {
    return `${tenantId}-items`
  }
  // design the Proxy to pass through all method calls to the Firestore SDK
  function collection(collectionName) {
    const collectionRef = accountDoc.collection(collectionName)

    return new Proxy(collectionRef, {
      get: (_, prop) => {
        if (prop === 'doc') {
          return (docId) => collectionRef.doc(docId)
        } else {
          return (...args) => collectionRef[prop](...args)
        }
      }
    })
  }

  function batch() {
    const batch = db.batch()
    return new Proxy(batch, {
      get: (_, prop) => {
        return (docRef, ...args) => {
          if (docRef) {
            let path = ''
            let currentRef = docRef

            // Traverse the parent structure to the root
            while (currentRef) {
              path = currentRef.id + (path ? '/' + path : '')
              currentRef = currentRef.parent
            }

            // Check if the path starts with the tenantId
            if (!path.startsWith(tenantId)) {
              throw new Error("Batch operation can only be performed on tenant's collections")
            }
          }

          return batch[prop](docRef, ...args)
        }
      }
    })
  }

  return { collection, batch, invoiceItemsCollection }
}

/* Special wrapper for if you need access to the root firestore without tenant prefix
  Currently restricted to read-only for extra safety, in addition to Firestore rules */

async function readOnlyGlobalCollection() {
  const user = firebaseAuth.currentUser
  if (!user) {
    throw new Error('User not signed in')
  }

  function collection(collectionName) {
    const collectionRef = db.collection(collectionName)

    return new Proxy(collectionRef, {
      get: (_, prop) => {
        // Allow read methods on collections
        if (['get', 'where', 'orderBy', 'limit', 'startAt', 'startAfter', 'endAt', 'endBefore'].includes(prop)) {
          return (...args) => collectionRef[prop](...args)
        } else if (prop === 'doc') {
          // Allow 'doc' but return a proxied DocumentReference
          return (docId) => {
            const docRef = collectionRef.doc(docId)
            // Proxy the DocumentReference to only allow 'get'
            return new Proxy(docRef, {
              get: (_, prop) => {
                if (prop === 'get') {
                  return (...args) => docRef[prop](...args)
                } else {
                  throw new Error(`Method ${prop} is not allowed on this document reference [Priceworx].`)
                }
              }
            })
          }
        } else {
          // Disallow all other properties/methods
          throw new Error(`Property or method ${prop} is not allowed on this collection [Priceworx].`)
        }
      }
    })
  }

  return { collection }
}

export {
  firebase,
  firebaseApp,
  firebaseAuth,
  createTenantFirestore,
  readOnlyGlobalCollection,
  firebaseFunctions,
  firebaseStorage,
  firebaseRemoteConfig
}
