import initialState from './state'

export function resetState(state) {
  Object.assign(state, initialState())
}

export function setVersion(state, value) {
  state.appVersion = value
}
export function setPluginReady(state, value) {
  state.gpsPluginReady = value
}

export function setGpsDenied(state, value) {
  state.gpsDenied = value
}

export function setGpsAlwaysOn(state, value) {
  state.gpsAlwaysOn = value
}

export function setGeofenceOn(state, value) {
  state.gpsGeofenceOn = value
}

export function setPermissionState(state, value) {
  state.gpsPermissionState = value
}

export function setGeofences(state, value) {
  state.geofences = value
}

export function addFenceEvent(state, value) {
  state.fenceEvents.push(value)
}

export function clearFenceEvents(state, value) {
  state.fenceEvents = []
}
export function addLocationEvent(state, value) {
  state.locationEvents.push(value)
}

export function clearLocationEvents(state, value) {
  state.locationEvents = []
}

export function setCurrentFence(state, value) {
  state.currentFence = value
}

export function setRequiresManualSignIn(state, value) {
  state.requiresManualSignIn = value
}
export function setManualSignInEvent(state, value) {
  state.manualSignInEvent = value
}

export function setRequiresManualSignOut(state, value) {
  state.requiresManualSignOut = value
}

export function setManualSignOutEvent(state, value) {
  state.manualSignOutEvent = value
}

export function setSignedIn(state, value) {
  state.signedIn = value
}

export function setSignedOut(state, value) {
  state.signedOut = value
}

export function setDeviceId(state, value) {
  state.deviceId = value
}

export function setDeviceInfo(state, value) {
  state.deviceInfo = value
}

export function setRecentActivity(state, value) {
  state.recentActivity = value
}
