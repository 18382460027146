import { firebase, firebaseAuth, firebaseStorage, firebaseFunctions, createTenantFirestore } from 'boot/firebase'
import { uid } from 'quasar'
import { i18n } from 'src/boot/i18n'
import moment from 'moment'

export async function removeDraft(context, item) {
  const firebaseStore = await createTenantFirestore()
  await firebaseStore.collection('draftInvoiceItems').doc(item.itemId).delete()
  const filesToDelete = item.removeFiles ? item.removeFiles : []
  if (filesToDelete.length > 0) {
    var deleteFilesFn = firebaseFunctions.httpsCallable('plots-deleteFilesForPlotItem')
    await deleteFilesFn({ itemIds: filesToDelete })
  }
}
export async function updateDraftValues(context, rec) {
  const firebaseStore = await createTenantFirestore()
  const docId = rec.id
  const filesToDelete = rec.removeFiles ? rec.removeFiles : []
  rec.values = JSON.parse(JSON.stringify(rec.values)) // we want a none state copy
  const dateFields = rec.fields.filter((f) => f.type === 'date')
  for (const v of rec.values) {
    const valueIds = Object.getOwnPropertyNames(v)
    for (const id of Object.values(valueIds)) {
      if (dateFields.find((f) => f.id === id)) {
        const date = moment(v[id], 'DD-MM-YYYY')
        if (date.isValid()) {
          v[id] = new Date(date.format('YYYY-MM-DD'))
        }
      }
    }
  }
  const eventFields = rec.fields.filter((f) => f.type === 'calendarEvent')
  for (const v of rec.values) {
    const valueIds = Object.getOwnPropertyNames(v)
    for (const id of Object.values(valueIds)) {
      if (eventFields.find((f) => f.id === id)) {
        const dateStart = moment(v[id].start, 'DD-MM-YYYY')
        const dateEnd = moment(v[id].end, 'DD-MM-YYYY')
        if (dateStart.isValid() && dateEnd.isValid()) {
          v[id] = {
            start: new Date(dateStart.format('YYYY-MM-DD')),
            end: new Date(dateEnd.format('YYYY-MM-DD'))
          }
        } else {
          v[id] = {
            start: null,
            end: null
          }
        }
      }
    }
  }
  const photoFields = rec.fields.filter((f) => f.type === 'photo')
  for (const valueObj of rec.values) {
    const valueIds = Object.getOwnPropertyNames(valueObj)
    for (const id of Object.values(valueIds)) {
      const photoField = photoFields.find((f) => f.id === id)
      if (photoField) {
        if (valueObj[id]) {
          if (valueObj[id].timestamp) {
            const ts = moment(valueObj[id].timestamp, 'DD-MM-YYYY')
            if (ts.isValid()) {
              valueObj[id].timestamp = new Date(ts.format('YYYY-MM-DD'))
            } else {
              valueObj[id].timestamp = null
            }
          }
          delete valueObj[id].thumbnailUrl // don't save
        }
      }
    }
  }
  const photoGroupFields = rec.fields.filter((f) => f.type === 'photoGroup')
  for (const photoField of photoGroupFields) {
    for (const index in rec.values) {
      if (typeof rec.values[index][photoField.id] !== 'undefined') {
        // Found the value by ID
        const value = rec.values[index][photoField.id]
        if (value && Array.isArray(value)) {
          for (const image of value) {
            if (image.timestamp) {
              const ts = moment(image.timestamp, 'DD-MM-YYYY')
              if (ts.isValid()) {
                image.timestamp = new Date(ts.format('YYYY-MM-DD'))
              } else {
                image.timestamp = null
              }
            }
            delete image.thumbnailUrl // we don't want to save this
          }
        }
      }
    }
  }

  const scannerFields = rec.fields.filter((f) => f.type === 'documentScanner')
  for (const valueObj of rec.values) {
    const valueIds = Object.getOwnPropertyNames(valueObj)
    for (const id of Object.values(valueIds)) {
      const scannerField = scannerFields.find((f) => f.id === id)
      if (scannerField) {
        if (valueObj[id] && Array.isArray(valueObj[id].pages)) {
          // eslint-disable-next-line no-unused-vars
          for (var page of valueObj[id].pages) {
            if (page.timestamp) {
              const ts = moment(page.timestamp, 'DD-MM-YYYY')
              if (ts.isValid()) {
                page.timestamp = new Date(ts.format('YYYY-MM-DD'))
              } else {
                page.timestamp = null
              }
            }
            delete page.thumbnailUrl
            delete page.imageData
          }
        }
      }
    }
  }

  if (filesToDelete.length > 0) {
    var deleteFilesFn = firebaseFunctions.httpsCallable('plots-deleteFilesForPlotItem')
    await deleteFilesFn({ itemIds: filesToDelete })
  }
  const obj = {
    values: rec.values
  }
  // If we need to update the invoice then include it in the merge
  if (rec.invoice) {
    obj.invoice = rec.invoice
  }
  await firebaseStore.collection('draftInvoiceItems').doc(docId).set(obj, {
    merge: true
  })
  const draft = await firebaseStore.collection('draftInvoiceItems').doc(docId).get()
  context.commit(
    'invoices/editCustomItem',
    {
      ...draft.data(),
      id: draft.id
    },
    {
      root: true
    }
  )
}
export async function createDraftInvoiceItem(context, installationItem) {
  const firebaseStore = await createTenantFirestore()
  const docId = `${firebaseAuth.currentUser.uid}_${installationItem.id}`
  const draftExists = await firebaseStore.collection('draftInvoiceItems').doc(docId).get()
  console.log('Creating Draft of', installationItem)
  if (draftExists.exists) {
    console.log('[!] Draft already exists for: ', docId)
    context.commit(
      'invoices/editCustomItem',
      {
        ...draftExists.data(),
        id: draftExists.id
      },
      {
        root: true
      }
    )
    return
  }
  const draftForm = await getForm(context, installationItem.form)
  if (!draftForm) {
    throw new Error('Form does not exist!')
  }
  const invoiceId =
    context.rootState.invoices.workingInvoice && context.rootState.invoices.workingInvoice.id
      ? context.rootState.invoices.workingInvoice.id
      : null
  if (!invoiceId) {
    throw new Error('There is no open invoice')
  }
  delete draftForm.id
  draftForm.form = installationItem.form
  draftForm.inventoryItem = true
  draftForm.builder = installationItem.builder
  draftForm.development = installationItem.development
  draftForm.houseType = installationItem.houseType
  draftForm.plot = installationItem.plot
  draftForm.plotItem = installationItem.id
  draftForm.title = `${draftForm.formName}`
  draftForm.itemName = `${draftForm.formName}` // backwards compatibility
  draftForm.engineer = firebaseAuth.currentUser.uid
  draftForm.engineerName = context.rootState.invoices.workingInvoice.engineerName
  draftForm.engineerEmail = context.rootState.invoices.workingInvoice.engineerEmail
  draftForm.hasFormItem = true
  draftForm.isDraft = true
  draftForm.approvalStatus = 'Draft'
  draftForm.invoice = invoiceId
  draftForm.createdAt = new Date()
  // Prepare default form values
  const values = []
  for (const f of draftForm.fields) {
    const obj = {}
    obj[f.id] = null
    values.push(obj)
  }
  // Replace any null Number values with zero
  const numberFields = draftForm.fields.filter((f) => f.type === 'hours')
  for (const obj of values) {
    const valueIds = Object.getOwnPropertyNames(obj)
    for (const id of Object.values(valueIds)) {
      const numberField = numberFields.find((f) => f.id === id)
      if (numberField) {
        if (obj[id] === null) {
          obj[id] = 0
        }
      }
    }
  }
  draftForm.values = values

  const ref = firebaseStore.collection('draftInvoiceItems').doc(docId)
  await ref.set(draftForm, {
    merge: true
  })

  context.commit(
    'invoices/editCustomItem',
    {
      ...draftForm,
      id: ref.id
    },
    { root: true }
  )
}
export async function createDraftForm(context, newDoc) {
  // TODO: copy approach from createDraftInvoiceItem
  // const firebaseStore = await createTenantFirestore()
  // const docId = `${newDoc.engineer}_${newDoc.internalId}`
  // const existsDoc = await firebaseStore.collection('draftApprovals').doc(docId).get()
  // if (existsDoc.exists) {
  //   return {
  //     fields: existsDoc.data().fields,
  //     config: existsDoc.data().config,
  //     values: existsDoc.data().values
  //   }
  // } else {
  //   await firebaseStore
  //     .collection('draftApprovals')
  //     .doc(docId)
  //     .set({
  //       ...newDoc,
  //       createdDate: new Date()
  //     })
  //   return {
  //     fields: newDoc.fields,
  //     config: newDoc.config,
  //     values: newDoc.values
  //   }
  // }
}
export async function getForm(context, formId) {
  const firebaseStore = await createTenantFirestore()

  const form = await firebaseStore.collection('customForms').doc(formId).get()
  if (form.exists) {
    return {
      id: form.id,
      ...form.data()
    }
  } else {
    return null
  }
}

export async function deleteStorageItems({ commit, dispatch, state }, items) {
  var deleteFilesFn = firebaseFunctions.httpsCallable('plots-deleteFilesForPlotItem')
  await deleteFilesFn({ itemIds: items })
}

export async function getThumbnailURL({ commit, dispatch, state }, payload) {
  let imageRef = null
  let converting = false
  if (typeof payload === 'string') {
    imageRef = payload
    converting = false
  } else {
    imageRef = payload.imageRef
    converting = true
  }
  const imageNotFound = 'https://placehold.co/200x200/orange/white?text=Not\nFound'
  const imageError = 'https://placehold.co/200x200/red/white?text=Image\nError'
  const imageProcessing = 'https://placehold.co/200x200/orange/white?text=Reopen\nDraft'

  if (!imageRef) {
    return imageNotFound
  }

  const storageRef = firebaseStorage.ref()
  const maxRetries = 10
  let attempts = 0

  async function attemptFetch() {
    try {
      const url = await storageRef.child(imageRef).getDownloadURL()
      return url // Successfully fetched the URL
    } catch (error) {
      if (error.message.includes('object-not-found')) {
        if (attempts < maxRetries) {
          attempts++ // Increment the attempt counter
          await new Promise((resolve) => setTimeout(resolve, 1000)) // Wait for 1 second
          return attemptFetch() // Try fetching again
        } else {
          if (converting) {
            return imageProcessing
          }
          return imageNotFound // Max attempts reached, return not found URL
        }
      } else {
        return imageError // Different error, return error URL
      }
    }
  }

  return attemptFetch()
}

export async function deleteHashes({ commit }, hashes) {
  const firebaseStore = await createTenantFirestore()

  const batch = firebaseStore.batch()
  for (const hash of hashes) {
    const hashRef = firebaseStore.collection('imagehashes').doc(hash)
    batch.delete(hashRef)
  }
  await batch.commit()
}

export async function hashExists({ commit }, hashes) {
  const firebaseStore = await createTenantFirestore()

  // Before we do anything, check if the given image already exists
  let hashExists = false
  for (const hash of hashes) {
    const hashRef = await firebaseStore.collection('imagehashes').doc(hash).get()
    if (hashRef.exists) {
      console.log(`[!] Hash ${hash} ex`)
      hashExists = true
    }
  }
  if (hashExists) {
    throw new Error(i18n.t('installationItems.messages.duplicatePhoto'))
  }
}

export async function saveHashes({ commit }, hashes) {
  const firebaseStore = await createTenantFirestore()

  const batch = firebaseStore.batch()
  for (const hash of hashes) {
    const hashRef = firebaseStore.collection('imagehashes').doc(hash)
    batch.set(hashRef, {
      hashType: 'sha256'
    })
  }
  await batch.commit()
}

export async function uploadImageB64(
  { commit, dispatch, state },
  { dataType, base64str, fileName, storagePath, docPath }
) {
  return new Promise((resolve, reject) => {
    const storageRef = firebaseStorage.ref()
    let conversionRequired = false
    const uuid = uid()
    let imageRef = storageRef.child(
      `/tenants/${firebaseAuth.currentUser.tenantId}/${storagePath}/${fileName}-${uuid}.jpg`
    )
    if (storagePath === 'convertheic') {
      conversionRequired = true
      imageRef = storageRef.child(
        `/tenants/${firebaseAuth.currentUser.tenantId}/${storagePath}/${fileName}-${uuid}.heic`
      )
      storagePath = `tenants/${firebaseAuth.currentUser.tenantId}/convertheic`
    }
    if (storagePath === 'cameraimages') {
      storagePath = `tenants/${firebaseAuth.currentUser.tenantId}/itemimages` // Firebase resize images extension will move anything from cameraimages into itemimages
    }
    if (storagePath === 'signatures') {
      storagePath = `tenants/${firebaseAuth.currentUser.tenantId}/signatures`
    }

    let uploadLocation = `${storagePath}/${fileName}-${uuid}` // the dimensions will be added by the calling Method
    if (conversionRequired) {
      // Overwrite this as the HEIC will be converted and moved automatically
      uploadLocation = `tenants/${firebaseAuth.currentUser.tenantId}/itemimages/${fileName}-${uuid}`
    }
    var metadata = {
      contentType: storagePath === 'convertheic' ? 'image/heic' : 'image/jpeg'
    }
    if (docPath) {
      // include the document path so we can find the invoice item later from a trigger
      metadata.customMetadata = {
        itemDocPath: docPath
      }
    }
    commit('setUploadState', null)
    commit('setUploadProgress', 0)
    commit('setUploadFile', `${fileName}-${uuid}.jpg`)
    window.uploadTask = imageRef.putString(base64str, dataType, metadata)
    window.uploadTask.on(
      'state_changed',
      function (snapshot) {
        const progress = snapshot.bytesTransferred / snapshot.totalBytes
        commit('setUploadProgress', progress)
        switch (snapshot.state) {
          case firebase.storage.TaskState.CANCELED: // or 'paused'
            commit('setUploadState', null)
            commit('setUploadProgress', 0)
            console.log('[!] Priceworx - Upload is cancelled')
            break
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('[!] Priceworx - Upload is paused but this is not supported')
            break
          case firebase.storage.TaskState.RUNNING: // or 'running'
            commit('setUploadState', 'RUNNING')
            break
        }
      },
      function (error) {
        console.log('[!] Priceworx', error)
        commit('setUploadState', null)
        commit('setUploadProgress', 0)
        switch (error.code) {
          case 'storage/unauthorized':
            reject(new Error(i18n.t('installationItems.messages.unauthorized')))
            break
          case 'storage/canceled':
            // User canceled the upload
            reject(new Error(i18n.t('installationItems.messages.uploadCancelled')))
            break
          default:
            // Unknown error occurred, inspect error.serverResponse
            reject(error)
            break
        }
      },
      function () {
        if (conversionRequired) {
          // Artificially breath a bit
          setTimeout(() => {
            resolve(uploadLocation)
          }, 1200)
        } else {
          resolve(uploadLocation)
        }
      }
    )
  })
}
