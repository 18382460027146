import initialState from './state'

export function resetState(state) {
  Object.assign(state, initialState())
}

export function setHideFooter(state, value) {
  state.hideFooter = value
}
export function setHideHeader(state, value) {
  state.hideHeader = value
}

export function setUploadState(state, value) {
  state.uploadState = value
}

export function setUploadProgress(state, percent) {
  state.uploadProgress = percent
}

export function setUploadFile(state, value) {
  state.uploadFile = value
}
