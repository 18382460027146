export default function () {
  return {
    tenantSelectRequired: false,
    availableTenants: [],
    currentTenant: null,
    authInitialised: false,
    isLoggedIn: false,
    userProfile: null,
    username: '',
    stateInitialised: false,
    gpsEnabled: false
  }
}
