<template>
  <div id="q-app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  mounted() {
  }
}
</script>

<style>
/* Make validation text white on login page only */
label.login-input > div.q-field__inner > div.q-field__bottom > div.q-field__messages > div {
  color: white;
}
/* Rounded edges on login fields */
label.login-input {
  border-radius: 5px;
}
/* Rounded edges on search fields */
.q-toolbar {
  border-radius: 12px;
}
/* Rounded bottom corners */
.header-toolbar {
  border-radius: 0px 0px 5px 5px;
  background: rgb(57,210,192);
  background: linear-gradient(93deg, #DFF5F4 0%, #E2E1F9 100%);
}
.priceworx-list {
  border-radius: 12px;
  border-color: #94DAF5;
}
.priceworx-list-invoice {
  border-radius: 12px;
  border-color: #94DAF5;
  margin-bottom: 5px;
}
.priceworx-list-green {
  border-color: #39D274;
}
.priceworx-item-bg {
  background-color: #39d27440 !important;
}
.priceworx-border-green {
  border: 2px solid #39D274!important;
}
</style>
