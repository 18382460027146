import Vue from 'vue'
import { Dialog } from 'quasar'
const plugin = {
  install() {
    Vue.config.errorHandler = function (err, vm, info) {
      console.log('DEBUG', err, info)
      if (info.includes('ChunkLoadError')) {
        Dialog.create({
          title: 'Update Applied',
          message: 'An updated version of the Priceworx console has been installed. The app will now refresh.'
        })
          .onOk(() => {
            window.location.reload() // Reload the page
          })
          .onCancel(() => {
            window.location.reload()
          })
          .onDismiss(() => {
            window.location.reload()
          })
      } else {
        // Handle other errors as needed
        console.error('[CATCHER] An error occurred:', err)
        console.log(JSON.stringify(err))
      }
    }
  }
}

Vue.use(plugin)
