import { createTenantFirestore } from 'boot/firebase'

export async function getCategories({ commit }) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('categories')
    .orderBy('position')
    .get()

  const docs = []
  if (!items.empty) {
    items.forEach(doc => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setCategories', docs)
}
