import Vue from 'vue'
import Vuex from 'vuex'
import { vuexfireMutations } from 'vuexfire'

import auth from './auth'
import messages from './messages'
import categories from './categories'
import builders from './builders'
import developments from './developments'
import plots from './plots'
import invoices from './invoices'
import itemoptions from './itemoptions'
import settings from './settings'
import signin from './signin'

Vue.use(Vuex)

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default function (/* { ssrContext } */) {
  const Store = new Vuex.Store({
    modules: {
      auth,
      messages,
      categories,
      builders,
      developments,
      plots,
      invoices,
      itemoptions,
      settings,
      signin
    },
    mutations: {
      ...vuexfireMutations
    },

    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEBUGGING
  })

  return Store
}
