const routes = [
  {
    path: '/',
    redirect: '/invoices',
    component: () => import('layouts/Authorised.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '/invoices',
        component: () => import('pages/Invoices.vue')
      },
      {
        path: '/invoices/manage/:invoiceId?',
        name: 'invoiceDetail',
        component: () => import('pages/InvoiceDetail.vue')
      },
      {
        path: '/builders',
        component: () => import('pages/builders.vue')
      },
      {
        path: '/builders/:builderId?/developments',
        component: () => import('pages/developments.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots',
        component: () => import('pages/plots.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/dayworks',
        component: () => import('pages/DayWorks.vue'),
        meta: {
          dayWorksMode: 'full' // probably redundant as part of PW-167/8
        }
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/travel',
        component: () => import('pages/Travel.vue'),
        meta: {
          dayWorksMode: 'full' // probably redundant as part of PW-167/8
        }
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/items',
        component: () => import('pages/InstallationItems.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/options',
        component: () => import('pages/SelectOption.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/qualitycontrol',
        component: () => import('pages/QualityControl.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/plancategories',
        component: () => import('pages/SitePlanCategories.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/plancategories/:categoryId?/plans',
        component: () => import('pages/SitePlans.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/viewdrawing',
        component: () => import('pages/ViewDrawing.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/drawingcategories',
        component: () => import('pages/TechnicalDrawingCategories.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/drawingcategories/:categoryId?/drawings',
        component: () => import('pages/TechnicalDrawings.vue')
      },
      {
        path: '/builders/:builderId?/developments/:developmentId?/plots/:plotId?/dayworks',
        component: () => import('pages/DayWorks.vue'),
        meta: {
          dayWorksMode: 'notravel' // probably redundant as part of PW-167/8
        }
      },
      {
        path: '/manageoptions/:itemType?',
        name: 'manageItemOptions',
        component: () => import('pages/ManageItemOptions.vue')
      },
      {
        path: '/results/:goBack?',
        component: () => import('pages/SearchResults.vue')
      },
      {
        path: '/approvals',
        component: () => import('pages/Approvals.vue')
      },
      {
        path: '/approvals/select',
        component: () => import('pages/ApprovalSelector.vue')
      },
      {
        path: '/forms/draft/:categoryId/item/:itemId?',
        component: () => import('pages/ApprovalDraftItemForm.vue')
      },
      {
        path: '/forms/select/:categoryId/item/:itemId?',
        component: () => import('pages/ApprovalItemForm.vue')
      },
      {
        path: '/signin',
        component: () => import('pages/SiteSignIn.vue')
      },
      {
        path: '/signin/visualise',
        component: () => import('pages/MapVisualiser.vue')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('layouts/Unauthorised.vue'),
    children: [
      {
        path: '/login/:email?',
        component: () => import('pages/Login.vue')
      },
      {
        path: '/login/register/:email/:uid/:tenantId',
        component: () => import('pages/RegisterAccount.vue')
      }
    ]
  },
  {
    path: '/reset',
    component: () => import('layouts/Unauthorised.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/ForgottenPassword.vue')
      }
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('pages/Error404.vue')
  }
]

export default routes
