import initialState from './state'

export function resetState(state) {
  Object.assign(state, initialState())
}

export function updatePlotSelections(state, selectedItems) {
  const items = state.plotItems
  items.forEach((item) => {
    item.selected = selectedItems.some((s) => s.id === item.id)
  })
  return items
}

export function setPlots(state, value) {
  // Natural sort technique on plotName
  value.sort((a, b) =>
    a.plotName.localeCompare(b.plotName, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true
    })
  )
  state.plots = value
}

export function clearPlots(state, value) {
  state.plots = []
}

export function setSearchResults(state, value) {
  state.plotSearchResults = value
}

export function setPlotItems(state, value) {
  value = value.sort((a, b) =>
    a.itemName.localeCompare(b.itemName, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true
    })
  )
  value = value.sort((a, b) =>
    a.categoryName.localeCompare(b.categoryName, navigator.languages[0] || navigator.language, {
      numeric: true,
      ignorePunctuation: true
    })
  )
  state.plotItems = value
}

export function setSelectedItem(state, id) {
  state.plotItems.forEach((item) => {
    if (item.id === id) {
      item.selected = !item.selected
    }
  })
}

export function setSelectionState(state, value) {
  state.plotItems.forEach((item) => {
    if (item.requirePhotos || item.requireDescription) {
      // Do nothing
    } else {
      item.selected = value
    }
  })
}
export function toggleSelectedItem(state, rec) {
  state.plotItems.forEach((item) => {
    if (item.id === rec.id) {
      item.selected = true
    }
  })
}
export function toggleDeselectedItem(state, rec) {
  state.plotItems.forEach((item) => {
    if (item.id === rec.id) {
      item.selected = false
    }
  })
}

export function setDrawings(state, value) {
  state.drawings = value
  const drawingCategories = []
  for (const doc of value) {
    const index = drawingCategories.find((rec) => rec.id === doc.category)
    if (!index) {
      drawingCategories.push({
        id: doc.category,
        name: doc.categoryName
      })
    }
  }
  state.drawingCategories = drawingCategories
}
