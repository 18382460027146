export default function () {
  return {
    invoiceSettingsReady: false,
    allowNewItems: false,
    openInvoiceCount: 0,
    workingInvoice: {},
    workingInvoiceItems: [],
    selectedInvoice: {},
    selectedInvoiceItems: [],
    selectedItems: [],
    selectedItemsTotal: 0.0,
    invoices: [],
    lastPlotsChosen: [],
    lastDayworksChosen: [],
    invoiceSettings: {},
    invoiceYear: [],
    invoiceMonthly: false,
    fireworksDisplayed: false,
    declinedItems: [],
    pendingItems: [],
    draftItems: [],
    recentlyApproved: [],
    editItemOptions: {}, // holds the record when editing options for a plot item
    editInvoiceOptions: {}, // holds the record when editing options for an invoice
    editDayworksItem: {}, // holds the record when editing Daywork Item for an invoice
    editCustomItem: {}, // holds the record when editing a Custom Item for an invoice
    approvalItems: []
  }
}
