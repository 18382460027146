import { Dialog, Notify, Loading, Platform } from 'quasar'
import { LocalNotifications } from '@capacitor/local-notifications'

const checkLocationNotificationPermissions = async function () {
  try {
    if (Platform.is.android || Platform.is.ios) {
      let { display } = await LocalNotifications.checkPermissions()
      console.log('[!] PriceworxPush Local Notifications display permission:', JSON.stringify(display))

      if (display === 'prompt') {
        ;({ display } = await LocalNotifications.requestPermissions())
      }

      if (display !== 'granted') {
        return 'denied'
      }

      return display
    } else {
      console.log('[!] PriceworxPush not supported in PWA mode')
    }
  } catch (e) {
    console.log('[!] PriceworxPush Local Notifications permissions error')
    console.error(e)

    return 'denied'
  }
}

const sendLocalNotification = async function (title, message) {
  try {
    if (Platform.is.android || Platform.is.ios) {
      const permissions = await checkLocationNotificationPermissions()
      if (permissions !== 'granted') {
        console.log('[!] Local Notifications DENIED')
        return
      }

      const notifs = await LocalNotifications.schedule({
        notifications: [
          {
            title: title,
            body: message || 'Unknown',
            id: Math.floor(Math.random() * 1000),
            schedule: {
              at: new Date(Date.now() + 1000)
            },
            attachments: null,
            actionTypeId: '',
            extra: null,
            foreground: true,
            launch: true
          }
        ]
      })
      console.log('[!] Scheduling notifications', JSON.stringify(notifs))
    } else {
      console.log('[!] PriceworxPush send not supported in PWA mode')
    }
  } catch (e) {
    console.log('[!] Local Notifications permissions error')
    console.error(e)
  }
}

const overrideSort = function (categories, docs) {
  if (categories.length > 0) {
    for (const doc of docs) {
      const position = categories.filter((cat) => cat.id === doc.category)
      if (position.length === 1 && typeof position[0].position !== 'undefined') {
        doc.categoryPosition = 'category' + position[0].position
        doc.categoryPositionNumeric = position[0].position
      } else {
        doc.categoryPosition = 'category0'
        doc.categoryPositionNumeric = 0
      }
    }
  }
  return docs
}

const displayDialog = function (title, message, fnOnOk) {
  Loading.hide()
  Dialog.create({
    title: title,
    html: true,
    message: message
  }).onOk(fnOnOk || (() => {}))
}

const displayToast = function (message, error = false, timeout = 1000) {
  if (error) {
    Notify.create({
      icon: 'warning',
      html: true,
      progress: true,
      timeout: timeout,
      message: message,
      position: 'top',
      color: 'red'
    })
  } else {
    Notify.create({
      icon: 'check',
      html: true,
      progress: true,
      timeout: timeout,
      message: message,
      position: 'top',
      color: 'green'
    })
  }
}

export default {
  displayDialog,
  displayToast,
  overrideSort,
  checkLocationNotificationPermissions,
  sendLocalNotification
}
