import initialState from './state'

export function resetState(state) {
  Object.assign(state, initialState())
}

export function setDevelopments(state, value) {
  state.developments = value
}
export function clearDevelopments(state) {
  state.developments = []
}

export function setSearchResults(state, value) {
  state.developmentSearchResults = value
}

export function setPlans(state, value) {
  state.plans = value
  const planCategories = []
  for (const doc of value) {
    const index = planCategories.find((rec) => rec.id === doc.category)
    if (!index) {
      planCategories.push({
        id: doc.category,
        name: doc.categoryName
      })
    }
  }
  state.planCategories = planCategories
}

export function setLastDrawing(state, value) {
  state.lastDrawing = value
}
