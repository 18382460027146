import BackgroundGeolocation from '@transistorsoft/capacitor-background-geolocation'
import { BackgroundGeolocationFirebase } from '@transistorsoft/capacitor-background-geolocation-firebase'
import { Device } from '@capacitor/device'
import { createTenantFirestore, firebaseAuth } from 'boot/firebase'
import { Platform, Dialog, LocalStorage } from 'quasar'
import { i18n } from 'src/boot/i18n'

export async function emailDebugLogs({ commit }) {
  // BackgroundGeolocation.logger
  //   .emailLog('tim@priceworx.co.uk')
  //   .then((success) => {
  //     console.log('[emailLog] SUCCESS')
  //   })
  //   .catch((error) => {
  //     console.log('[emailLog] ERROR: ', error)
  //   })
}

export async function getRecentSignIns({ commit, dispatch, state }) {
  const firebaseStore = await createTenantFirestore()
  const eventSnapshot = await firebaseStore
    .collection('geofenceEvents')
    .where('engineer', '==', firebaseAuth.currentUser.uid)
    .where('entered', '==', true)
    .where('exited', '==', true)
    .orderBy('enterAt', 'desc')
    .limit(10)
    .get()
  const recentActivity = []
  if (!eventSnapshot.empty) {
    for (const event of eventSnapshot.docs) {
      recentActivity.push({
        ...event.data(),
        id: event.id
      })
    }
  }
  commit('setRecentActivity', recentActivity)
}

export async function getCurrentGeofence({ commit, dispatch, state, rootState }) {
  // if (state.currentFence && Object.keys(state.currentFence).length > 0) {
  //   console.log('[!] A geofence has already been detected, use that')
  //   return
  // }
  // Ask firestore for last signed in event
  const firebaseStore = await createTenantFirestore()
  const eventSnapshot = await firebaseStore
    .collection('geofenceEvents')
    .where('engineer', '==', firebaseAuth.currentUser.uid) // v30Ha9RX1heUIylo1nLnynHMmrr1
    .orderBy('enterAt', 'desc')
    .limit(1)
    .get()
  console.log(`[!] Found ${eventSnapshot.size ? eventSnapshot.size : -1} existing geofence events for user`)
  if (!eventSnapshot.empty) {
    const signInEventRef = eventSnapshot.docs[0] // we only have 1 result
    if (signInEventRef.data().invalid === true) {
      commit('setSignedIn', false)
      console.log('[*] No active geofence 3')
      commit('setCurrentFence', {})
    } else if (signInEventRef.data().exited === true) {
      commit('setSignedIn', false)
      console.log('[*] No active geofence 2')
      commit('setCurrentFence', {})
    } else {
      // We're still in
      console.log('[*] Active geofence has been loaded')
      commit('setSignedIn', true)
      commit('setCurrentFence', {
        ...signInEventRef.data(),
        id: signInEventRef.id
      })
    }
  } else {
    console.log('[*] No active geofence 1')
    commit('setCurrentFence', {})
    commit('setSignedIn', false)
  }
}

export async function logGeoFenceEvent({ commit, dispatch, state, rootState }, event) {
  console.log('[*] Received Geofence event', JSON.stringify(event))
  // try {
  //   if (event.action === 'ENTER') {
  //     helpers.sendLocalNotification(
  //       i18n.t('siteSignIn.messages.signInNotification', {
  //         builderName: event.extras.builderName,
  //         developmentName: event.extras.developmentName
  //       })
  //     )
  //   } else if (event.action === 'EXIT') {
  //     helpers.sendLocalNotification(
  //       i18n.t('siteSignIn.messages.signOutNotification', {
  //         builderName: event.extras.builderName,
  //         developmentName: event.extras.developmentName
  //       })
  //     )
  //   }
  // } catch (error) {
  //   console.error('[!] Priceworx', error)
  //   helpers.displayDialog('Error', error.message)
  // }
}

export async function setAutoSignIn({ commit }, value) {
  const firebaseStore = await createTenantFirestore()
  firebaseStore.collection('users').doc(firebaseAuth.currentUser.uid).set(
    {
      autoSignIn: value
    },
    {
      merge: true
    }
  )
  commit('auth/setAutoSignIn', value, { root: true })
}
export async function initTracking({ commit, dispatch, state, rootState }) {
  try {
    BackgroundGeolocation.onConnectivityChange((connectivityChangeEvent) => {
      console.log(
        'BackgroundGeolocationDebug there has been a CONNECTIVITY change',
        JSON.stringify(connectivityChangeEvent)
      )
    })
    BackgroundGeolocation.onGeofence((event) => {
      console.log('BackgroundGeolocationDebug FENCE EVENT FIRED: ', JSON.stringify(event))
      commit('addFenceEvent', event)
      if (event.action === 'ENTER') {
        commit('setCurrentFence', event)
      } else if (event.action === 'EXIT') {
        commit('setCurrentFence', {})
      }
      dispatch('logGeoFenceEvent', event)
    })

    BackgroundGeolocation.onPowerSaveChange((isPowerSaveMode) => {
      console.log('BackgroundGeolocationDebug onPowerSaveChange: ', isPowerSaveMode)
      if (isPowerSaveMode) {
        console.log('BackgroundGeolocationDebug entering power save mode, background monitoring will stop')
      } else {
        console.log('BackgroundGeolocationDebug leaving power save mode, restarting geoFence monitoring if we can')
        startTracking(commit, dispatch)
      }
    })
    const user = firebaseAuth.currentUser
    if (!user) {
      throw new Error('User not signed in')
    }
    const tokenResult = await user.getIdTokenResult()
    const tenantId = tokenResult.claims.tenantId
    BackgroundGeolocationFirebase.configure({
      geofencesCollection: `/${tenantId}/account/geofenceActivity`
    })

    const status = await BackgroundGeolocation.ready({
      // Geolocation Config
      backgroundPermissionRationale: {
        title: "Allow Priceworx access to this device's location when closed or not in use?",
        message:
          "If you would like Priceworx to automatically Sign In or Out when you are at a Development then you'll need to enable {backgroundPermissionOptionLabel} location permission",
        positiveAction: 'Change to {backgroundPermissionOptionLabel}',
        negativeAction: 'Cancel - disable feature'
      },
      geofenceProximityRadius: 1000,
      desiredAccuracy: Platform.is.ios
        ? BackgroundGeolocation.DESIRED_ACCURACY_NAVIGATION
        : BackgroundGeolocation.DESIRED_ACCURACY_HIGH,
      // Activity Recognition
      stopTimeout: 5,
      geofenceInitialTriggerEntry: true,
      // Application config
      debug: false, // <-- enable this hear debug sounds.
      logLevel: BackgroundGeolocation.LOG_LEVEL_VERBOSE,
      stopOnTerminate: false, // <-- Allow the background-service to continue tracking when app terminated.
      startOnBoot: true // <-- Auto start tracking when device is powered-up.
    })
    console.log('BackgroundGeolocationDebug ready: ', JSON.stringify(status))
    LocalStorage.set(`pw_app_gps_${firebaseAuth.currentUser.uid}_acpt`, true)
    commit('setPluginReady', true)

    BackgroundGeolocation.onProviderChange((event) => {
      try {
        console.log('BackgroundGeolocationDebug: ', JSON.stringify(event))
        switch (event.status) {
          case BackgroundGeolocation.AUTHORIZATION_STATUS_DENIED:
            // Android & iOS
            console.log('BackgroundGeolocationDebug - Location authorization denied')
            commit('setGpsAlwaysOn', false)
            commit('setGpsDenied', true)
            break
          case BackgroundGeolocation.AUTHORIZATION_STATUS_ALWAYS:
            // Android & iOS
            LocalStorage.set(`pw_app_gps_${firebaseAuth.currentUser.uid}_acpt`, true)
            console.log('BackgroundGeolocationDebug- Location always granted')
            commit('setGpsAlwaysOn', true)
            commit('setPluginReady', true)
            commit('setGpsDenied', false)
            dispatch('initialiseFences')
            break
          case BackgroundGeolocation.AUTHORIZATION_STATUS_WHEN_IN_USE:
            // iOS only
            console.log('BackgroundGeolocationDebug - Location WhenInUse granted')
            commit('setGpsAlwaysOn', false)
            commit('setGpsDenied', true)
            break
        }
      } catch (error) {
        if (error.message.includes('Permission denied')) {
          console.log('BackgroundGeolocationDebug - Permission Denied2')
          commit('setGpsDenied', true)
          commit('setGpsAlwaysOn', false)
        } else {
          console.log('BackgroundGeolocationDebug4 error', error)
        }
      }
    })
  } catch (error) {
    if (error.message.includes('Permission denied')) {
      console.log('BackgroundGeolocationDebug on start - Permission Denied1')
      commit('setGpsDenied', true)
    } else {
      console.log('BackgroundGeolocationDebug3 ERROR', error)
    }
  }
}

export async function getGpsPermissionStatus({ commit }) {
  try {
    const providerState = await BackgroundGeolocation.getProviderState()
    console.log('BackgroundGeolocationDebug getGpsPermissionStatus is', providerState.status)
    switch (providerState.status) {
      case 3: // always on
        LocalStorage.set(`pw_app_gps_${firebaseAuth.currentUser.uid}_acpt`, true)
        commit('setPermissionState', 'always')
        commit('setGpsAlwaysOn', true)
        commit('setPluginReady', true)
        commit('setGpsDenied', false)
        break
      case 2: // denied
        commit('setPermissionState', 'denied')
        commit('setGpsAlwaysOn', false)
        commit('setGpsDenied', true)
        break
      case 4: // when in use
        commit('setPermissionState', 'denied')
        commit('setGpsAlwaysOn', false)
        commit('setGpsDenied', true)
        break
      default:
        commit('setPermissionState', 'other')
        commit('setGpsAlwaysOn', false)
        commit('setGpsDenied', false)
        break
    }
  } catch (error) {
    commit('setPermissionState', 'error')
    commit('setGpsAlwaysOn', false)
    commit('setGpsDenied', false)
    console.log('BackgroundGeolocationDebug2 ', error)
  }
}
// export async function startGeoFences({ commit }) {
//   try {
//     await BackgroundGeolocation.startGeofences()
//     commit('setGeofenceOn', true)
//   } catch (error) {
//     commit('setGeofenceOn', false)
//     console.log('BackgroundGeolocationDebug1 ', error)
//     return false
//   }
// }
// Helper function to introduce a delay using Promises
function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export async function startTrackingOnStartup({ commit, dispatch, state }) {
  setTimeout(async () => {
    await startTracking(commit, dispatch, state)
  }, 3000)
}

export async function startTracking(commit, dispatch, state) {
  console.log('BackgroundGeolocationDebug startTracking() has fired')
  try {
    const firebaseStore = await createTenantFirestore()
    const developmentSnapshot = await firebaseStore.collection('developments').where('completed', '==', false).get()
    const developmentsWithGeofences = []
    for (const development of developmentSnapshot.docs) {
      if (development.data().geofence && Object.keys(development.data().geofence).length > 0) {
        // It has a geofence
        if (development.data().userAccess && development.data().userAccess.length > 0) {
          if (development.data().userAccess.includes(firebaseAuth.currentUser.uid)) {
            developmentsWithGeofences.push(development)
          }
        } else {
          developmentsWithGeofences.push(development)
        }
      }
    }
    if (developmentsWithGeofences.length === 0) {
      console.log('[!] BackgroundGeolocationDebug - No active geofences, not starting GPS monitoring')
      commit('setGeofenceOn', false)
      return false
    }
    let engineerName = 'Unknown'
    const userProfile = await firebaseStore.collection('users').doc(firebaseAuth.currentUser.uid).get()
    if (userProfile.exists) {
      if (userProfile.data().sharedUser) {
        for (const name of userProfile.data().names) {
          engineerName += `${name.firstName} ${name.lastName}, `
        }
        engineerName = engineerName.replace(/,\s*$/, '')
      } else {
        engineerName = `${userProfile.data().firstName} ${userProfile.data().lastName}`
      }
    }

    let deviceId = state.deviceId
    let deviceInfo = state.deviceInfo
    if (deviceId === null) {
      const deviceIdentifier = await Device.getId()
      if (deviceIdentifier && deviceIdentifier.identifier) {
        deviceId = deviceIdentifier.identifier
      }
    }
    if (deviceInfo === null) {
      const deviceInfoJson = await Device.getInfo()
      if (deviceInfoJson) {
        deviceInfo = deviceInfoJson
      }
    }
    const geofences = []
    for (const development of developmentsWithGeofences) {
      geofences.push({
        identifier: development.id,
        radius: development.data().geofence.radius,
        latitude: development.data().geofence.lat,
        longitude: development.data().geofence.lng,
        notifyOnEntry: true,
        notifyOnExit: true,
        extras: {
          builder: development.data().builder,
          builderName: development.data().builderName,
          development: development.id,
          developmentName: development.data().developmentName,
          engineer: firebaseAuth.currentUser.uid,
          engineerName: engineerName,
          deviceId: deviceId,
          deviceInfo: deviceInfo
        }
      })
    }
    commit('setGeofences', geofences)
    const providerState = await BackgroundGeolocation.getProviderState()
    console.log('BackgroundGeolocationDebug Provider state INIT: ', JSON.stringify(providerState))
    if (providerState.status === 3) {
      // Always On
      LocalStorage.set(`pw_app_gps_${firebaseAuth.currentUser.uid}_acpt`, true)
      commit('setGpsAlwaysOn', true)
      try {
        commit('clearFenceEvents')
        await BackgroundGeolocation.removeGeofences()
        // await BackgroundGeolocation.getCurrentPosition({ samples: 3 })
        // console.log('BackgroundGeolocationDebug getting current position')
        // await delay(2000)
        await BackgroundGeolocation.addGeofences(geofences)
        console.log('BackgroundGeolocationDebug added geofences', JSON.stringify(geofences))
        await delay(2000)
        await BackgroundGeolocation.startGeofences()
        console.log('BackgroundGeolocationDebug LISTENING')
        commit('setGeofenceOn', true)
      } catch (error) {
        console.log('BackgroundGeolocationDebug FENCE ERROR', error)
      }
      // const activeFences = await BackgroundGeolocation.getGeofences()
      // console.log('BackgroundGeolocationDebug listening fences are', JSON.stringify(activeFences))
    } else {
      console.log('BackgroundGeolocationDebug must select Always On!')
      try {
        const status = await BackgroundGeolocation.requestPermission()
        console.log('BackgroundGeolocationDebug [requestPermission] success: ', status)
      } catch (status) {
        console.warn('BackgroundGeolocationDebug [requestPermission] FAILURE: ', status)
      }
    }
  } catch (error) {
    if (error && error.message && error.message.includes('Waiting for previous start action to complete')) {
      Dialog.create({
        title: i18n.t('label.confirm'),
        message: i18n.t('siteSignIn.messages.tryAgainStartGps'),
        html: true,
        color: 'negative',
        cancel: true,
        persistent: true,
        parent: this
      }).onOk(() => {
        dispatch('startTrackingOnStartup')
      })
    }
    console.log('BackgroundGeolocationDebugXXX error', error)
    commit('setGeofenceOn', false)
    commit('setGpsAlwaysOn', false)
  }
}
