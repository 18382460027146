import { App } from '@capacitor/app'
import { PushNotifications } from '@capacitor/push-notifications'
import { createTenantFirestore, firebaseAuth } from 'boot/firebase'
import { Platform } from 'quasar'
import helpers from 'src/plugins/helpers'

export async function initPush({ commit, dispatch, state, rootState }) {
  try {
    if (!Platform.isCapacitor) {
      console.log('[!] Push notifications not supported on web')
      return
    }
    await PushNotifications.addListener('registration', async (token) => {
      console.info('[PriceworxPush] Registration token: ', token.value)
      const firebaseStore = await createTenantFirestore()
      await firebaseStore.collection('fcmTokens').doc(firebaseAuth.currentUser.uid).set({
        token: token.value,
        engineer: firebaseAuth.currentUser.uid,
        isAndroid: !!Platform.is.android,
        isIos: !!Platform.is.ios,
        timestamp: new Date()
      })
    })

    await PushNotifications.addListener('registrationError', (err) => {
      console.error('[PriceworxPush] Registration error: ', err.error)
    })

    await PushNotifications.addListener('pushNotificationReceived', async (notification) => {
      console.log('[PriceworxPush] Push notification received: ', JSON.stringify(notification))
      const { isActive } = await App.getState()
      console.log('[PriceworxPush] App State is', isActive)
      if (isActive && Platform.is.android) {
        helpers.sendLocalNotification(notification.title, notification.body)
      }
    })

    await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
      console.log('[PriceworxPush] Push notification action performed', notification.actionId, notification.inputValue)
    })

    let permStatus = await PushNotifications.checkPermissions()

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions()
    }

    if (permStatus.receive === 'granted') {
      await PushNotifications.register()
    } else {
      console.log('[PriceworxPush] Push notification registration DENIED')
    }
  } catch (error) {
    console.log('[PriceworxPush] PushNotifications ERROR', error)
  }
}
