import { createTenantFirestore } from 'boot/firebase'

export async function getPlans({ commit }, developmentId) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('drawings')
    .where('development', '==', developmentId)
    .where('houseType', '==', null)
    .where('visible', '==', true)
    .orderBy('fileName')
    .get()
  const docs = []
  if (!items.empty) {
    items.forEach((doc) => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setPlans', docs)
}

export async function getDevelopmentsByName({ commit }, searchText) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('developments')
    .orderBy('lcDevelopmentName')
    .where('completed', '==', false)
    .startAt(searchText.toLowerCase())
    .endAt(searchText.toLowerCase() + '\uf8ff')
    .get()

  const docs = []
  if (!items.empty) {
    items.forEach((doc) => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setSearchResults', docs)
}

export async function getDevelopments({ commit }, builderId) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('developments')
    .where('builder', '==', builderId)
    .where('completed', '==', false)
    .orderBy('developmentName')
    .get()
  const docs = []
  if (!items.empty) {
    items.forEach((doc) => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setDevelopments', docs)
}
