export function resetState(state) {
  Object.assign(state, {
    userProfile: null
  })
}

export function setAuthInitialised(state, value) {
  state.authInitialised = value
}
export function setLoggedIn(state, value) {
  state.isLoggedIn = value
}
export function setUserProfile(state, value) {
  state.userProfile = value
}

export function overrideDisplayMonths(state, value) {
  state.userProfile.displayMonths = value // will get overwritten on auth change
}

export function setEulaAccepted(state, value) {
  state.userProfile.eulaAccepted = value
}

export function setUsername(state, value) {
  state.username = value
}

export function setTenantSelectRequired(state, value) {
  state.tenantSelectRequired = value
}

export function setAvailableTenants(state, value) {
  state.availableTenants = value
}

export function setAutoSignIn(state, value) {
  state.userProfile.autoSignIn = value
}
