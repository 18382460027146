export default function () {
  return {
    appVersion: 0,
    deviceId: null,
    deviceInfo: null,
    gpsPermissionState: null,
    gpsDenied: false,
    gpsPluginReady: false,
    gpsAlwaysOn: false,
    gpsGeofenceOn: false,
    geofences: [
      // {
      //   latitude: 36.51036990711481,
      //   longitude: -4.954044880104453,
      //   radius: 200
      // },
      // {
      //   latitude: 36.48902661594809,
      //   longitude: -4.949356893665229,
      //   radius: 200
      // }
    ],
    currentFence: {},
    signedIn: false,
    signedOut: false,
    fenceEvents: [],
    locationEvents: [],
    requiresManualSignIn: false,
    manualSignInEvent: {},
    requiresManualSignOut: false,
    manualSignOutEvent: {},
    recentActivity: []
  }
}
