import { createTenantFirestore } from 'boot/firebase'

export async function getBuildersByName({ commit }, searchText) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('builders')
    .orderBy('lcBuilderName')
    .startAt(searchText.toLowerCase())
    .endAt(searchText.toLowerCase() + '\uf8ff')
    .get()

  const docs = []
  if (!items.empty) {
    items.forEach(doc => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setSearchResults', docs)
}

export async function getBuilders({ commit }) {
  const firebaseStore = await createTenantFirestore()

  const items = await firebaseStore
    .collection('builders')
    .orderBy('builderName')
    .get()
  const docs = []
  if (!items.empty) {
    items.forEach(doc => {
      docs.push({
        id: doc.id,
        ...doc.data()
      })
    })
  }
  commit('setBuilders', docs)
}
